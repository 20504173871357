<template>
    <div class="wrapper">
        <div class="history-table">
            <div class="history-header">
                <div class="header-block">
                    <p>Оправленно</p>
                </div>
                <div class="header-block">
                    <p>Тема</p>
                </div>
                <div class="header-block">
                    <p>Содержание</p>
                </div>
            </div>
            <div class="history-content">
                <div class="content-row" v-for="item in historyNotifications" :key="item.id">
                    <div class="content-block">
                        <p>
                            {{ new Date(item.dateAdded).toLocaleDateString('ru-RU',  { year: 'numeric', month: 'long', day: 'numeric'}) }}
                        </p>
                    </div>
                    <div class="content-block">
                        <p v-html="JSON.parse(item.header.replaceAll(/\\n/gm, '<br>'))"></p>
                    </div>
                    <div class="content-block">
                        <p v-html="JSON.parse(item.content.replaceAll(/\\n/gm, '<br>'))"></p>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';

    export default {
        name: "HistoryNotifications",
        computed: {
            ...mapGetters('notifications', ['historyNotifications']),
        },
    }
</script>

<style lang="less">

    .history-header, .content-row{
        display: flex;
        gap: 60px;
        padding: 12px 0;

        .header-block, .content-block{
            flex: 1;

            &:last-child{
                flex: 3;
            }
        }
    }

    .content-row{
        border-bottom: 1px solid #EEEEEE;
        max-width: 1250px;
    }

    .header-block{
        font-weight: 500;
        color: #7D909B;
    }
</style>